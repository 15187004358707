const routes = [
  {
    index: true,
    label: 'Cole French',
    path: '/',
  },
  {
    firstInBurger: true,
    label: 'About',
    path: '/',
  },
];

export default routes;
